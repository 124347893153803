import React from "react"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { SlugLink } from '../components/utils'
import { Button } from 'react-bootstrap'
const TagsPage = ({
  data: {
    privateGraphql: { tags },
    site: {
      siteMetadata: { title, domainName },
    },
  },
  location
}) => {
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }
  return (
    <Layout location={location} title={title}>
      <SEOTAG
        title={`All Tags`}
        description={`All Tags - ${domainName}`}
      // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <div className="w-grid__columns w-grid__columns--one">
        <div>
          <h1>Tags</h1>

          {tags.map((tag, index) => (
            <Link key={index} to={`/tags/${(tag.slugname)}`}>
              <Button variant="dark" style={{ margin: 2 }}>{tag.name}</Button>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}
export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        domainName
      }
    }
    privateGraphql {
        tags(limit:2000,skip:0) {
          id
          name
          slugname
        }
      }
  }
`